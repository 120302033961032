import React from "react"
import { Container } from "theme-ui"
import { Layout, SEO } from "../components"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container px={3} py={[4, 5, 100]} css="text-align: center;">
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Container>
  </Layout>
)

export default NotFoundPage
